import {
  MdArrowLeft,
  MdArrowRight,
  MdChevronLeft,
  MdDelete,
  MdDeleteOutline,
  MdMenu,
  MdMoreVert,
  MdSell,
  MdShare,
  MdTrendingUp,
} from "react-icons/md";
import "../styles/trade-page.scss";
import RichTextEditor from "../components/RichTextEditor";
import { useNavigate, useParams } from "react-router-dom";
import Select from "../components/selectcontrol";
import { useCallback, useEffect, useRef, useState } from "react";
import { sendDelete, sendGet, sendPost, sendPut } from "../../common/request";
import Urls from "../../common/links";
import useSubAccount from "../hooks/useSubAccount";
import moment from "moment";
import numberFormat from "../../common/format";
import Loader from "../components/Loader";
import tickData from "../data/tick-data.json";
import TradePagePanel from "../enums/trade-page-panel";
import Checkbox from "../components/checkbox";
import RuleShow from "../utils/rule-show";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hideTitle } from "../../redux/header/action";
import DeleteConfirmationModalComponent from "../components/Modal/DeleteConfirmationModalComponent";
import TradeShareModal from "../components/Modal/TradeShareModal";
import {getGrossPnL, getNetROI, getPlannedRMultiple, getRealizedRMultiple, getTickInfo} from "../../common/methods";
import {useTheme} from "../Providers/ThemeContext";

function TradePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [setupTags, setSetupTags] = useState([]);
  const [mistakeTags, setMistakeTags] = useState([]);
  const [customTags, setCustomTags] = useState([]);
  const [selectedSetupIndices, setSelectedSetupIndices] = useState([]);
  const [selectedMistakeIndices, setSelectedMistakeIndices] = useState([]);
  const [selectedCustomIndices, setSelectedCustomIndices] = useState([]);
  const [profitTarget, setProfitTarget] = useState(0);
  const [stopLoss, setStopLoss] = useState(0);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [trade, setTrade] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const subAccount = useSubAccount();
  const [tradeNote, setTradeNote] = useState(undefined);
  const [trades, setTrades] = useState([]);
  const [currentPanel, setCurrentPanel] = useState(TradePagePanel.Stats);
  const [playbooks, setPlaybooks] = useState([]);
  const [hasPlaybookChanged, setPlaybookChanged] = useState(false);
  const [hasPlaybookRulesChanged, setPlaybookRulesChanged] = useState(false);
  const [selectedPlaybook, setSelectedPlaybook] = useState(-1);
  const [attachedPlaybook, setAttachedPlaybook] = useState(undefined);
  const [isShareModalShown, setIsShareModalShown] = useState(false);
  const checkedRules = useRef(0);
  const totalRules = useRef(0);
  const tradeState = useRef(RuleShow.TradeWinner);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(hideTitle());
  const theme = useTheme();

  const loadCriteriaGroups = (playbook) => {
    checkedRules.current = 0;
    totalRules.current = 0;
    return playbook.criteriaGroups.map((group, index) => {
      group.rules = group.rules.map((rule, index) => {
        if (rule.showRule === RuleShow.Always) {
          totalRules.current += 1;
        } else {
          if (rule.showRule === tradeState.current) {
            totalRules.current += 1;
          }
        }

        if (rule.checked) {
          checkedRules.current += 1;
        }

        return rule;
      });
      return group;
    });
  };

  const loadTrade = useCallback(async () => {
    if (subAccount && id) {
      setIsLoading(true);
      const response = await sendGet(Urls.GetTrade(id, subAccount.id), true);
      if (response.error) {
        console.log(response.error);
      } else {
        console.log(response.trade)
        if (response.trade.data.PnL > 0) {
          tradeState.current = RuleShow.TradeWinner;
        } else if (response.trade.data.PnL < 0) {
          tradeState.current = RuleShow.TradeLoser;
        } else if (response.trade.data.PnL === 0) {
          tradeState.current = RuleShow.TradeBreakEven;
        }

        setSelectedTags(response.trade.tags);
        setTrade(response.trade.data);
        setProfitTarget(response.trade.data.ProfitTarget);
        setStopLoss(response.trade.data.StopLoss);

        const playbook = response.trade.playbooks[0];
        if (playbook) {
          playbook.criteriaGroups = loadCriteriaGroups(playbook);
        }
        setAttachedPlaybook(playbook);
      }
      setIsLoading(false);
    }
  }, [id, subAccount]);

  const loadAllTrades = useCallback(async () => {
    if (subAccount) {
      const response = await sendGet(Urls.GetTrades(subAccount.id), true);
      if (response.error) {
        console.log(response.error);
      } else {
        const sortedTrades = response.sort((a, b) => {
          const aTime = new Date(a.data.OpenTime).getTime();
          const bTime = new Date(b.data.OpenTime).getTime();
          return aTime - bTime;
        });
        setTrades(sortedTrades);
      }
    }
  }, [subAccount]);

  const loadTags = async () => {
    let response = await sendGet(Urls.GetTags, true);
    if (!response.error) {
      const tags = response.tags;
      setTags(tags);
    }
  };

  const loadPlaybooks = useCallback(async () => {

    if(!subAccount) {
      return;
    }

    const response = await sendGet(Urls.GetPlaybooksList(subAccount.id), true);
    if (response.error) {
      console.log(response.error);
    } else {
      setPlaybooks(response.playbooks);
    }
  }, [subAccount])

  const resetData = () => {
    setAttachedPlaybook(undefined);
    setPlaybookRulesChanged(false);
    setPlaybookChanged(false);
    setSelectedPlaybook(-1);
    setSelectedCustomIndices([]);
    setSelectedMistakeIndices([]);
    setSelectedSetupIndices([]);
    setSelectedTags([]);
    setProfitTarget(0);
    setStopLoss(0);
  };

  useEffect(() => {
    resetData();
  }, [id]);

  useEffect(() => {
    loadPlaybooks();
  }, [loadPlaybooks()]);

  const loadNote = useCallback(async () => {
    if (id && subAccount) {
      setIsLoading(true);
      const response = await sendGet(
        Urls.GetNoteForTrade(id, subAccount.id),
        true
      );
      if (response.error) {
        console.log(response.error);
        setTradeNote(undefined);
      } else {
        setTradeNote(response.note);
      }
      setIsLoading(false);
    }
  }, [id, subAccount]);

  useEffect(() => {
    if (attachedPlaybook) {
      playbooks.forEach((playbook, index) => {
        if (playbook.id === attachedPlaybook.id) {
          setSelectedPlaybook(index);
          return;
        }
      });
    }
  }, [attachedPlaybook, playbooks]);

  useEffect(() => {
    loadTags();
    loadTrade();
    loadNote();
  }, [loadTrade, loadNote, id, subAccount]);

  useEffect(() => {
    loadAllTrades();
  }, [loadAllTrades]);

  useEffect(() => {
    if (tags && selectedTags) {
      const sTags = tags
        .filter((tag) => tag.type === "Setup")
        .map((tag) => tag.name);
      const mTags = tags
        .filter((tag) => tag.type === "Mistake")
        .map((tag) => tag.name);
      const cTags = tags
        .filter((tag) => tag.type === "Custom")
        .map((tag) => tag.name);

      if (selectedTags.length > 0) {
        const selectedS_Tags = selectedTags.filter(
          (tag) => tag.type === "Setup"
        );
        setSelectedSetupIndices(
          selectedS_Tags.map((t) => sTags.indexOf(t.name))
        );

        const selectedM_Tags = selectedTags.filter(
          (tag) => tag.type === "Mistake"
        );
        setSelectedMistakeIndices(
          selectedM_Tags.map((t) => mTags.indexOf(t.name))
        );

        const selectedC_Tags = selectedTags.filter(
          (tag) => tag.type === "Custom"
        );
        setSelectedCustomIndices(
          selectedC_Tags.map((t) => cTags.indexOf(t.name))
        );
      }

      setSetupTags(sTags);
      setMistakeTags(mTags);
      setCustomTags(cTags);
    }
  }, [tags, selectedTags]);

  const onTagChange = (value, type) => {
    if (!hasDataChanged) {
      setHasDataChanged(true);
    }

    if (type === "Setup") {
      setSelectedSetupIndices(value);
    } else if (type === "Mistake") {
      setSelectedMistakeIndices(value);
    } else if (type === "Custom") {
      setSelectedCustomIndices(value);
    }
  };

  const onProfitTargetChange = (value) => {
    setHasDataChanged(true);
    setProfitTarget(value);
  };

  const onStopLossChange = (value) => {
    setHasDataChanged(true);
    setStopLoss(value);
  };

  const onDiscardChanges = () => {
    setSelectedTags([]);
    setSelectedSetupIndices([]);
    setSelectedMistakeIndices([]);
    setSelectedCustomIndices([]);
    loadTrade();
    setHasDataChanged(false);
  };

  const onSaveChanges = async () => {
    if (id && subAccount) {
      setIsLoading(true);

      const allTags = [
        ...selectedSetupIndices.map((index) => setupTags[index]),
        ...selectedMistakeIndices.map((index) => mistakeTags[index]),
        ...selectedCustomIndices.map((index) => customTags[index]),
      ];

      const tagIds = allTags.map(
        (tag) => tags.filter((t) => t.name === tag)[0].id
      );

      const tagAssignmentResponse = await sendPost(
        Urls.AssignTags,
        {
          tradeId: id,
          tagIds: tagIds,
        },
        true
      );

      if (tagAssignmentResponse.error) {
        console.log(tagAssignmentResponse.error);
      }

      const response = await sendPost(
        Urls.UpdateTrade(id, subAccount.id),
        {
          profitTarget: profitTarget,
          stopLoss: stopLoss,
        },
        true,
        "application/json",
        true
      );

      if (response.error) {
        console.log(response.error);
      } else {
        loadTrade();
        setHasDataChanged(false);
      }
      setIsLoading(false);
    }
  };

  const getInitialTarget = (trade) => {
    const difference = trade.Side === 'LONG' ? profitTarget - trade.OpenPrice : trade.OpenPrice - profitTarget;
    const tickInfo = getTickInfo(trade);
    if (tickInfo) {
      return (
        difference *
        (1 / tickInfo.tickSize) *
        tickInfo.tickValue *
        Math.abs(parseInt(trade.OpenVolume))
      );
    } else {
      return 0;
    }
  };

  const getTradeRisk = (trade) => {
    const difference = trade.Side === 'LONG' ? trade.OpenPrice - stopLoss : stopLoss - trade.OpenPrice;
    const tickInfo = getTickInfo(trade);

    if (tickInfo) {
      return numberFormat.format(
        difference *
          (1 / tickInfo.tickSize) *
          tickInfo.tickValue *
          Math.abs(parseInt(trade.OpenVolume))
      );
    } else {
      return "$0.00";
    }
  };

  const getTicks = (trade) => {
    let keys = Object.keys(tickData);
    for (const key of keys) {
      if (trade.SecurityId?.startsWith(key)) {
        const tickInfo = tickData[key];
        return ((trade.ClosePrice - trade.OpenPrice) / tickInfo.tickSize) * (Math.abs(trade.OpenVolume));
      }
    }
    return 0;
  };

  const getPoints = (trade) => {
    if(trade.Side === "LONG") {
      return trade.ClosePrice - trade.OpenPrice;
    } else {
      return trade.OpenPrice - trade.ClosePrice;
    }
  };

  const onNoteSave = async (content) => {
    if (id && subAccount.id) {
      setIsLoading(true);

      const response = await sendPost(
        Urls.CreateOrUpdateNote(tradeNote ? tradeNote.id : ""),
        {
          content: content,
          tradeId: id,
          subAccountId: subAccount.id,
          title: `${trade.SecurityId.split("@")[0]}: ${moment(
            trade.OpenTime
          ).format("MMM DD, YYYY")}`,
          type: "TradeNote",
        },
        true,
        "application/json",
        true
      );
      if (response.error) {
        console.log(response.error);
      } else {
        loadNote();
      }
      setIsLoading(false);
    }
  };

  const onNoteDiscard = () => {
    loadNote();
  };

  const getNextTrade = () => {
    let tradeIndex = -1;
    const currentTradeId = parseInt(id);
    trades.forEach((t, index) => {
      if (t.id === currentTradeId) {
        tradeIndex = index;
      }
    });
    if (tradeIndex < trades.length - 1) {
      return trades[tradeIndex + 1].id;
    } else {
      return undefined;
    }
  };

  const getPrevTrade = () => {
    let tradeIndex = -1;
    const currentTradeId = parseInt(id);
    trades.forEach((t, index) => {
      if (t.id === currentTradeId) {
        tradeIndex = index;
      }
    });
    if (tradeIndex > 0) {
      return trades[tradeIndex - 1].id;
    } else {
      return undefined;
    }
  };

  const onNextTrade = () => {
    const nextTradeId = getNextTrade();
    if (nextTradeId !== undefined) {
      navigate(`/trade/${nextTradeId}`);
    }
  };

  const onPrevTrade = () => {
    const prevTradeId = getPrevTrade();
    if (prevTradeId !== undefined) {
      navigate(`/trade/${prevTradeId}`);
    }
  };

  const onPlaybookChange = (value) => {
    setSelectedPlaybook(value);
    setPlaybookChanged(true);
  };

  const onPlaybookDiscard = () => {
    setPlaybookChanged(false);
    if (attachedPlaybook) {
      playbooks.forEach((playbook, index) => {
        if (playbook.id === attachedPlaybook.id) {
          setSelectedPlaybook(index);
          return;
        }
      });
    } else {
      setSelectedPlaybook(-1);
    }
  };

  const onPlaybookSave = async () => {
    setIsLoading(true);

    const playbook = playbooks[selectedPlaybook];

    const response = await sendPost(
      Urls.AttachPlaybookTrade(playbook.id, id),
      {},
      true,
      "application/json",
      true
    );
    if (response.error) {
      console.log(response.error);
    } else {
      loadTrade();
    }
    setPlaybookChanged(false);
    setIsLoading(false);
  };

  const onPlaybookCriteriaChange = (groupId, ruleId, value) => {
    setPlaybookRulesChanged(true);

    if (value) {
      checkedRules.current += 1;
    } else {
      checkedRules.current -= 1;
    }

    const newPlaybook = { ...attachedPlaybook };
    newPlaybook.criteriaGroups = newPlaybook.criteriaGroups.map((group) => {
      if (group.id === groupId) {
        group.rules = group.rules.map((rule) => {
          if (rule.id === ruleId) {
            rule.checked = value;
          }
          return rule;
        });
      }
      return group;
    });
    setAttachedPlaybook(newPlaybook);
  };

  const onSavePlaybookRules = async () => {
    if (attachedPlaybook) {
      setIsLoading(true);
      setPlaybookRulesChanged(false);
      const criteriaGroups = attachedPlaybook.criteriaGroups;
      const response = await sendPut(
        Urls.UpdatePlaybookRules(attachedPlaybook.id, id),
        { criteriaGroups: criteriaGroups },
        true,
        "application/json",
        true
      );
      if (response.error) {
        console.log(response.error);
      }
      setIsLoading(false);
    }
  };

  const onDiscardPlaybookRules = () => {
    loadTrade();
    setPlaybookRulesChanged(false);
  };

  const detachPlaybook = async () => {
    if (attachedPlaybook) {
      setPlaybookChanged(false);
      setPlaybookRulesChanged(false);
      setSelectedPlaybook(-1);
      setIsLoading(true);
      const response = await sendPost(
        Urls.DetachPlaybookTrade(attachedPlaybook.id, id),
        undefined,
        true,
        "",
        true
      );
      if (response.error) {
        console.log(response.error);
      } else {
        loadTrade();
      }
      setIsLoading(false);
    }
  };

  const [isDeleteConfirmationModalShown, setIsDeleteConfirmationModalShown] =
    useState(false);

  const onDeleteConfirmationModalClose = async (isSaved) => {
    setIsDeleteConfirmationModalShown(false);
    if (isSaved) {
      setIsLoading(true);
      const response = await sendDelete(Urls.DeleteTrade(id), {}, true);
      setIsLoading(false);
      if (response.error) {
        console.log(response.error);
      } else {
        navigate(-1);
      }
    }
  };

  const showShareModal = () => {
    setIsShareModalShown(true);
  };

  const onShareModalClose = (isSaved) => {
    setIsShareModalShown(false);
  };

  return (
    <>
      <DeleteConfirmationModalComponent
        isShown={isDeleteConfirmationModalShown}
        message={
          "Are you sure you want to delete this trade? This action is irreversible"
        }
        onClose={onDeleteConfirmationModalClose}
      />
      <TradeShareModal
        isShown={isShareModalShown}
        onClose={onShareModalClose}
        tradeId={id}
        subAccountId={subAccount?.id}
      />
      {isLoading ? (
        <Loader
          text={"Hold Tight! We are loading the data"}
          fullScreen={true}
        />
      ) : (
        <div className={`page trade-page ${theme.getThemeClass()}`}>
          <div className="trade-header">
            <div className="horizontal-container">
              <div className="back-btn" onClick={() => navigate(-1)}>
                <div className="icon-container">
                  <MdChevronLeft />
                </div>
              </div>
              <div className="btn-container">
                <div
                  className={`trade-button ${
                    getPrevTrade() !== undefined ? "" : "disabled"
                  }`}
                  onClick={onPrevTrade}
                >
                  <MdArrowLeft />
                </div>
                <div
                  className={`trade-button ${
                    getNextTrade() !== undefined ? "" : "disabled"
                  }`}
                  onClick={onNextTrade}
                >
                  <MdArrowRight />
                </div>
              </div>
              <div className="title-container">
                <div className="title">
                  {trade.SecurityId ? trade.SecurityId.split("@")[0] : "-"}
                  <div className="date">
                    {trade.OpenTime
                      ? moment(trade.OpenTime).format("ddd, MMM, DD, YYYY")
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="horizontal-container">
              <div className="primary-btn" onClick={showShareModal}>
                <MdShare /> {t("trade_button_share_trade")}
              </div>
              <div
                className="danger-btn"
                onClick={() => setIsDeleteConfirmationModalShown(true)}
              >
                <MdDeleteOutline /> {t("trade_button_delete_trade")}
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="trade-details-container">
              <div className="trade-details">
                <div className="trade-tabs-container">
                  <div
                    className={`trade-tab ${
                      currentPanel === TradePagePanel.Stats ? "active" : ""
                    }`}
                    onClick={() => setCurrentPanel(TradePagePanel.Stats)}
                  >
                    {t("trade_stats_tab_name")}
                  </div>
                  <div
                    className={`trade-tab ${
                      currentPanel === TradePagePanel.Playbook ? "active" : ""
                    }`}
                    onClick={() => setCurrentPanel(TradePagePanel.Playbook)}
                  >
                    {t("trade_playbook_tab_name")}
                  </div>
                </div>
                {currentPanel === TradePagePanel.Stats ? (
                  <div className="trade-details-items-container">
                    <div
                      className={`trade-pnl ${
                        trade.PnL >= 0 ? "positive" : "negative"
                      }`}
                    >
                      <div className="label">{t("trade_net_pnl")}</div>
                      <div className="price">
                        {numberFormat.format(trade.PnL)}
                      </div>
                    </div>
                    <div className="field-items">
                      <div className="field">
                        <div className="label">{t("trade_field_side")}</div>
                        <div
                          className={`value ${
                            trade.Side === "SHORT" ? "negative" : "positive"
                          }`}
                        >
                          {trade.Side}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_contracts_traded")}
                        </div>
                        <div className="value">
                          {Math.abs(trade.OpenVolume)}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">{t("trade_field_points")}</div>
                        <div className="value">
                          {(getPoints(trade)).toFixed(2)}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">{t("trade_field_ticks")}</div>
                        <div className="value">{getTicks(trade).toFixed(2)}</div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_ticks_per_contract")}
                        </div>
                        <div className="value">
                          { (getTicks(trade) / Math.abs(trade.OpenVolume)).toFixed(2)}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_commission")}
                        </div>
                        <div className="value">
                          {trade.Commission
                            ? typeof trade.Commission == "string"
                              ? numberFormat.format(
                                  Math.abs(parseFloat(trade.Commission))
                                )
                              : numberFormat.format(Math.abs(trade.Commission))
                            : "$0"}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">{t("trade_field_net_roi")}</div>
                        <div className="value">{getNetROI(trade)}%</div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_gross_pnl")}
                        </div>
                        <div className="value">{getGrossPnL(trade, true)}</div>
                      </div>
                      <div className="field">
                        <div className="label">{t("trade_field_playbook")}</div>
                        <div className="value">
                          {attachedPlaybook ? attachedPlaybook.name : "--"}
                          <div
                            className="clickable"
                            onClick={() => {
                              if (attachedPlaybook) {
                                navigate(
                                  `/playbook-details/overview/${attachedPlaybook.id}`
                                );
                              }
                            }}
                          >
                            <MdTrendingUp />
                          </div>
                        </div>
                      </div>
                      <div className="field field-input">
                        <div className="label">
                          {t("trade_field_profit_target")}
                        </div>
                        <div className="value">
                          <div className="input-group">
                            <div className="input-prefix">
                              <div className="prefix">$</div>
                              <input
                                type="number"
                                name="profitTarget"
                                placeholder="0.00"
                                value={profitTarget}
                                onChange={(e) =>
                                  onProfitTargetChange(e.currentTarget.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field field-input">
                        <div className="label">
                          {t("trade_field_stop_loss")}
                        </div>
                        <div className="value">
                          <div className="input-group">
                            <div className="input-prefix">
                              <div className="prefix">$</div>
                              <input
                                type="number"
                                name="stopLoss"
                                placeholder="0.00"
                                value={stopLoss}
                                onChange={(e) =>
                                  onStopLossChange(e.currentTarget.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_initial_target")}
                        </div>
                        <div
                          className={`value ${
                            getInitialTarget(trade) >= 0
                              ? "positive"
                              : "negative"
                          }`}
                        >
                          {numberFormat.format(getInitialTarget(trade))}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_trade_risk")}
                        </div>
                        <div className="value negative">
                          {getTradeRisk(trade)}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_planned_r_multiple")}
                        </div>
                        <div className="value">
                          {getPlannedRMultiple(trade)}R
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_realized_r_multiple")}
                        </div>
                        <div className="value">
                          {getRealizedRMultiple(trade)}R
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_average_entry")}
                        </div>
                        <div className="value">
                          {numberFormat.format(trade.OpenPrice)}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_average_exit")}
                        </div>
                        <div className="value">
                          {numberFormat.format(trade.ClosePrice)}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_entry_time")}
                        </div>
                        <div className="value">
                          {moment(trade.OpenTime).format("HH:mm:ss")}
                        </div>
                      </div>
                      <div className="field">
                        <div className="label">
                          {t("trade_field_exit_time")}
                        </div>
                        <div className="value">
                          {moment(trade.CloseTime).format("HH:mm:ss")}
                        </div>
                      </div>
                      <div className="field field-multiselect">
                        <div className="label">
                          <div className="setup">
                            <MdSell />
                          </div>
                          {t("trade_field_setup_tags")}
                        </div>
                        <div className="value">
                          <Select
                            options={setupTags}
                            selectedIndex={selectedSetupIndices}
                            allowMultiple={true}
                            onChange={(indices) =>
                              onTagChange(indices, "Setup")
                            }
                            itemName="Tag"
                            selectedOptionBackground={"tag-bg-setup"}
                            isSearchable={true}
                            onOptionRender={(option) => {
                              return (
                                <div className="horizontal-container">
                                  <div className="setup">
                                    <MdSell />
                                  </div>
                                  {option}
                                </div>
                              );
                            }}
                            onSelectedOptionRender={(option) => {
                              return (
                                <div className="horizontal-container">
                                  <div className="setup">
                                    <MdSell />
                                  </div>
                                  {option}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="field field-multiselect">
                        <div className="label">
                          <div className="mistake">
                            <MdSell />
                          </div>
                          {t("trade_field_mistake_tags")}
                        </div>
                        <div className="value">
                          <Select
                            options={mistakeTags}
                            selectedIndex={selectedMistakeIndices}
                            allowMultiple={true}
                            onChange={(indices) =>
                              onTagChange(indices, "Mistake")
                            }
                            itemName="Tag"
                            isSearchable={true}
                            selectedOptionBackground={"tag-bg-mistake"}
                            onOptionRender={(option) => {
                              return (
                                <div className="horizontal-container">
                                  <div className="mistake">
                                    <MdSell />
                                  </div>
                                  {option}
                                </div>
                              );
                            }}
                            onSelectedOptionRender={(option) => {
                              return (
                                <div className="horizontal-container">
                                  <div className="mistake">
                                    <MdSell />
                                  </div>
                                  {option}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="field field-multiselect">
                        <div className="label">
                          <div className="custom">
                            <MdSell />
                          </div>
                          {t("trade_field_custom_tags")}
                        </div>
                        <div className="value">
                          <Select
                            options={customTags}
                            selectedIndex={selectedCustomIndices}
                            allowMultiple={true}
                            onChange={(indices) =>
                              onTagChange(indices, "Custom")
                            }
                            itemName="Tag"
                            selectedOptionBackground={"tag-bg-custom"}
                            isSearchable={true}
                            onOptionRender={(option) => {
                              return (
                                <div className="horizontal-container">
                                  <div className="custom">
                                    <MdSell />
                                  </div>
                                  {option}
                                </div>
                              );
                            }}
                            onSelectedOptionRender={(option) => {
                              return (
                                <div className="horizontal-container">
                                  <div className="custom">
                                    <MdSell />
                                  </div>
                                  {option}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                      {hasDataChanged ? (
                        <div className="field-buttons">
                          <div
                            className="field-save-btn"
                            onClick={onSaveChanges}
                          >
                            {t("save_changes")}
                          </div>
                          <div
                            className="field-discard-btn"
                            onClick={onDiscardChanges}
                          >
                            {t("discard_changes")}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : currentPanel === TradePagePanel.Playbook ? (
                  <div className="playbook-panel">
                    <div className="main-container">
                      <div className="input-group">
                        <div className="horizontal-container">
                          <label>{t("trade_field_playbook")}</label>
                          {attachedPlaybook ? (
                            <div
                              className="danger-btn"
                              onClick={detachPlaybook}
                            >
                              {t("detach")}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <Select
                          options={
                            playbooks ? playbooks.map((p) => p.name) : []
                          }
                          isSearchable={true}
                          selectedIndex={selectedPlaybook}
                          onChange={onPlaybookChange}
                        />
                      </div>
                      {hasPlaybookChanged ? (
                        <div className="field-buttons">
                          <div
                            className="field-save-btn"
                            onClick={onPlaybookSave}
                          >
                            {t("save_changes")}
                          </div>
                          <div
                            className="field-discard-btn"
                            onClick={onPlaybookDiscard}
                          >
                            {t("discard_changes")}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {attachedPlaybook ? (
                      <div className="criteria-groups-container">
                        <div className="rules-bar">
                          <div className="title">
                            {t("trade_playbook_rules_followed")}
                          </div>
                          <div className="bar-container">
                            <div className="bar">
                              <div
                                className="fill"
                                style={{
                                  width: `${
                                    (checkedRules.current /
                                      totalRules.current) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                            <div className="label">
                              {checkedRules.current}/{totalRules.current}
                            </div>
                          </div>
                        </div>
                        {attachedPlaybook.criteriaGroups.map((group, index) => {
                          return (
                            <div key={index} className="criteria-group">
                              <div className="title">{group.title}</div>
                              <div className="rules">
                                {group.rules.map((rule, index) => {
                                  if (rule.showRule !== RuleShow.Always) {
                                    if (rule.showRule !== tradeState.current) {
                                      return <></>;
                                    }
                                  }

                                  return (
                                    <div className="rule" key={index}>
                                      <Checkbox
                                        title={rule.value}
                                        value={rule.checked}
                                        onChange={(v) =>
                                          onPlaybookCriteriaChange(
                                            group.id,
                                            rule.id,
                                            v
                                          )
                                        }
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                        {hasPlaybookRulesChanged ? (
                          <div className="field-buttons">
                            <div
                              className="field-save-btn"
                              onClick={onSavePlaybookRules}
                            >
                              {t("save_changes")}
                            </div>
                            <div
                              className="field-discard-btn"
                              onClick={onDiscardPlaybookRules}
                            >
                              {t("discard_changes")}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="additional-details">
                <RichTextEditor
                  onSaveChanges={onNoteSave}
                  content={tradeNote ? tradeNote.content : ""}
                  onDiscardChanges={onNoteDiscard}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TradePage;
